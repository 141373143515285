import React from "react";
import { Container, Row, Col } from "reactstrap";
import SectionSubtitle from "./SectionSubtitle";
import classes from "../../styles/services.module.css";
import ServicesItem from "./ServicesItem";

const Services = () => {
  return (
    <section id="services">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className={`${classes.services__container}`}>
              <div>
                <ServicesItem title="App Development" icon="ri-apps-line" />

                <ServicesItem title="Web Development" icon="ri-computer-line" />
              </div>

              <ServicesItem
                title="Full-Stack Development"
                icon="ri-code-s-slash-line"
              />
            </div>
          </Col>

          <Col lg="6" md="6" className={`${classes.service__title}`}>
            <SectionSubtitle subtitle="What I do" />
            <h3 className="mb-4">Better Experience</h3>
            <div>
              <p>
                with hiring me, you will not only get a developer, whom will
                follow the direction of your choice, but also a person, whom if
                needed, can advise you on optimised prioritizations of the
                tasks, faster delivery in term of business value, optimised
                overall development approach and optimised user journey from UX
                standpoint.
                <br />
                <br />
                <b>Skills</b>
                <br />
                <div>
                  <ul>
                    <li>Flutter</li>
                    <li>ReactJS</li>
                    <li>NodeJs</li>
                    <li>Firebase</li>
                    <li>Mysql</li>
                    <li>DigitalOcean</li>
                  </ul>
                </div>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
