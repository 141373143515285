import React from "react";
import { Container, Row, Col } from "reactstrap";
import SectionSubtitle from "./SectionSubtitle";
import classes from "../../styles/contact.module.css";
import Form from "./Form";

const Contact = () => {
  return (
    <section id="contact" className={`${classes.contact}`}>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <SectionSubtitle subtitle="Contact me" />
            <h3 className="mt-4 mb-4">Contact with me</h3>
            <div>
              <ul className={`${classes.contact__info__list}`}>
                <li className={`${classes.info__item}`}>
                  <span>
                    <i className="ri-mail-line"></i>
                  </span>
                  <div>
                    <p>manthansutariya11@gmail.com</p>
                  </div>
                  ̦
                </li>
              </ul>
            </div>

            <div className={`${classes.social__links}`}>
              <a
                href="https://www.youtube.com/channel/UCOm4od4C9sX8BZOLlAIgegA"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0px 2px" }}
              >
                <i className="ri-youtube-line"></i>
              </a>
              <a
                href="https://github.com/ManthanSutariya11"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0px 2px" }}
              >
                <i className="ri-github-line"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/manthan-sutariya-153b2217a/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0px 2px" }}
              >
                <i className="ri-linkedin-line"></i>
              </a>
              <a
                href="https://twitter.com/SutariyaManthan"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0px 2px" }}
              >
                <i className="ri-twitter-line"></i>
              </a>
            </div>
          </Col>

          <Col lg="6" md="6">
            <Form />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
