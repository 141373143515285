import Hero from "./components/UI/Hero";
import Services from "./components/UI/Services";
import About from "./components/UI/About";
import Portfolio from "./components/UI/Portfolio";
import Testimonial from "./components/UI/Testimonial";
import Contact from "./components/UI/Contact";
import "./styles/globals.css";
import "./styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <Layout>
      <Hero />
      <Services />
      <About />
      {/* <Portfolio /> */}
      <Testimonial />
      <Contact />
    </Layout>
  );
}

export default App;
