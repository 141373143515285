import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import SectionSubtitle from "./SectionSubtitle";
import network from "../../assets/images/Connected world.png";
import Slider from "react-slick";
import classes from "../../styles/testimonial.module.css";

const Testimonial = () => {
  const testimonial = [
    {
      name: "Jamie P",
      country: "Germany",
      review:
        "One of my favorite developers. Looking forward for the next projects with you! Thanks",
    },
    {
      name: "Michele R",
      country: "Italy",
      review: "All good",
    },
    {
      name: "Serwaa B",
      country: "Germany",
      review:
        "He is really professional and knows what he's doing I can definitely recommend him!!! communication was very good all the time.",
    },
    {
      name: "Jamie P",
      country: "Germany",
      review: "Great like always ",
    },
    {
      name: "Emmanuel O",
      country: "United States",
      review: "Great to work with to get the job done",
    },
    {
      name: "Jamie P",
      country: "Germany",
      review:
        "Great like always! He is my favorite freelancer. Looking forward to many new projects with you",
    },
    {
      name: "Anshuman A",
      country: "India",
      review:
        "He really knows his work and Will work with him in the future for sure",
    },
    {
      name: "Emmanuel O",
      country: "United States",
      review:
        "Great coder. Provides great support after work is done. Will work with him again. A++",
    },
    {
      name: "Jaskaran S",
      country: "India",
      review: "Excellent Freelancer. Most reliable person on this platform",
    },
    {
      name: "Emmanuel O",
      country: "United States",
      review: "Very responsive",
    },
    {
      name: "Jaskaran S",
      country: "India",
      review:
        "Wow! What a wonderfull Guy. Went over and above his call of duty. Would love to keep coming back to him again and again with more work.",
    },
  ];

  const settings = {
    dots: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6" className={`${classes.testimonial__img}`}>
            <img alt="network-img" src={network} width="400" height="400" />
          </Col>

          <Col lg="6" md="6">
            <SectionSubtitle subtitle="Testimonials" />
            <div className={`${classes.testimonial__client}`}>
            <h4 className="mt-4 mb-5">What my client says</h4>
            </div>

            <Slider {...settings} key={"Slider"}>
              {testimonial.map((e) => {
                return (
                  <div
                    className={`${classes.testimonial__item}`}
                    key={e.review}
                  >
                    <div className={`${classes.testimonial__client}`}>
                      {/* <Image
                      alt="client-img"
                      src="/images/hero.jpg"
                      width="50"
                      height="50"
                      className=" rounded-2"
                    /> */}

                      <div>
                        <h6>{e.name}</h6>
                        <h6>{e.country}</h6>
                      </div>
                    </div>
                    <div>
                      <p>{e.review}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
