import React, { useState } from "react";
import classes from "../../styles/form.module.css";
import axios from "axios";

const axios_client = axios.create({
  timeout: 3600,
});

const Form = () => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientMessage, setClientMessage] = useState("");

  const [didSentMail, setDidSentMail] = useState(false);

  const sendEmail = async (props) => {
    const response = await axios_client.post(
      "https://us-central1-manthanit-8eb35.cloudfunctions.net/sendMail",
      {
        to: "manthansutariya11@gmail.com",
        subject: props.subject,
        text: props.to + " - " + props.text,
      }
    );
    setClientName("");
    setClientEmail("");
    setClientMessage("");
    setDidSentMail(true);
  };

  // const submitHandler = () => {
  //   e.preventDefault();
  // };
  return (
    <form className={`${classes.form}`} onSubmit={() => {}}>
      <div className={`${classes.form__group}`}>
        <input
          type="text"
          placeholder="Your Name"
          required
          onChange={(e) => setClientName(e.target.value)}
        />
      </div>
      <div className={`${classes.form__group}`}>
        <input
          type="email"
          placeholder="Email Address"
          required
          onChange={(e) => setClientEmail(e.target.value)}
        />
      </div>
      <div className={`${classes.form__group}`}>
        <textarea
          type="text"
          rows={5}
          placeholder="Message"
          required
          onChange={(e) => setClientMessage(e.target.value)}
        />
      </div>
      <div
        className="primary__btn"
        type="submit"
        style={{
          background: didSentMail ? "mediumspringgreen" : "#171f38",
          color: didSentMail ? "black" : "white",
          width: "100px",
          cursor: "pointer",
        }}
        onClick={() => {
          const d = {
            to: clientEmail,
            subject: "Query From " + clientName,
            text: clientMessage,
          };
          sendEmail(d);
        }}
      >
        {didSentMail ? "Sent" : "Send"}
      </div>
    </form>
  );
};

export default Form;
