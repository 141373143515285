import React from "react";
import SectionSubtitle from "./SectionSubtitle";
import { Container, Row, Col } from "reactstrap";
import classes from "../../styles/hero.module.css";

const Hero = () => {
  return (
    <section className={`${classes.hero}`}>
      <Container>
        <Row>
          {/* ========== hero content ============= */}
          <Col lg="6" md="6">
            <div className={`${classes.hero__content}`}>
              <SectionSubtitle subtitle="Hello" />
              <h2 className="mt-3 mb-3">I&apos;m Manthan Sutariya</h2>
              <h5 className="mb-4">Software Developer</h5>
              <div className="mt-5">
                <button className="primary__btn">
                  <a
                    href="https://www.freelancer.com/u/Manthanpatel11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Hire me
                  </a>
                </button>
                <button className="secondary__btn">
                  <a href={"/manthan_resume.pdf"} download>
                    Download CV
                  </a>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
